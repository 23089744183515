<template>
  <div class="navgation-bar">
    <div class="logoBox">
      <img src="../assets/images/logo.png">
      <span>福州奔阳信息科技</span>
    </div>
    <div class="border"></div>
    <div class="navgation" @mouseleave="showDetail = false">
      <div class="navBox" v-for="(item,index) in navList" :key="index">
        <span class="mainLabel" :class="{'activeMain':selectIndex==index}" @mouseover="showDetail=true" @click="changeNav(item,index,item.children?item.children[0]:null,0)">{{item.name}}</span>
        <transition name="fade">
          <div class="childrenBox" v-show="showDetail">
            <span class="childTxt" :class="{'activeChild':selectChild==childIndex&&selectIndex==index}" v-for="(childItem,childIndex) in item.children" :key="childIndex" @click="changeNav(item,index,childItem,childIndex)">
              {{childItem.name}}
            </span>
          </div>
        </transition>
<!--        <div v-show="showDetail&&index==navList.length-1" class="close" @click="showDetail=false">-->
<!--          <img src="../assets/images/close.png">-->
<!--        </div>-->
      </div>
    </div>
    <button class="nav-open-btn active" @click="drawer = true">
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </button>

    <el-drawer
        :visible.sync="drawer"
        :direction="direction"
        :append-to-body="true"
        :modal-append-to-body="false">
      <div class="navgation1">
        <div class="navBox1" v-for="(item,index) in navList" :key="index">
          <div @click="changeNav(item,index,item.children?item.children[0]:null,0)">
            <span class="mainLabel1" :class="{'activeMain':selectIndex==index}" @click="chooseIndex=index">{{item.name}}</span>
          </div>
          <transition name="fade">
            <div class="childrenBox1" v-if="chooseIndex==index">
              <span class="childTxt1" :class="{'activeChild':selectChild==childIndex&&selectIndex==index}" v-for="(childItem,childIndex) in item.children" :key="childIndex" @click="changeNav(item,index,childItem,childIndex)">
                {{childItem.name}}
              </span>
            </div>
          </transition>
        </div>
      </div>
    </el-drawer>

    <div class="mobile">
      <img src="../assets/images/mobile.png">
      <span>电话：15985761802</span>
    </div>
  </div>
</template>
<script>
import {web_menu} from "../utils/api";
export default {
  data() {
    return {
      navUrl:"",
      navList:[
        {
          name:'首页',
          type:0,//0-站内锚点跳转 1-跳转新页面
          url:'/indexPage',
          children:[
            {
              label:'业务范畴',
              id:1
            },
            {
              label:'企业简介',
              id:2
            },
            {
              label:'案例中心',
              id:3
            },
            {
              label:'荣誉资质',
              id:4
            },
            {
              label:'合作伙伴',
              id:5
            }
          ]
        },
        {
          name:'业务范畴',
          type:1,//0-站内锚点跳转 1-跳转新页面
          url:'/businessScope',
          children:[
            {
              label:'地震预警发布系统',
              url:'/publishingSystem'
            },
            {
              label:'地震预警设备',
              url:'/device'
            },
            {
              label:'软硬件研发',
              url:'/softHardware'
            },
            {
              label:'信息化集成',
              url:'/informatization'
            },
          ]
        },
        {
          name:'关于我们',
          type:0,//0-站内锚点跳转 1-跳转新页面
          url:'/aboutUs',
          children:[
            {
              label:'企业简介',
              id:1
            },
            {
              label:'荣誉资质',
              id:2
            },
            {
              label:'企业新闻',
              id:3
            },
            {
              label:'合作伙伴',
              id:4
            },
          ]
        },
        {
          name:'案例中心',
          type:0,//0-站内锚点跳转 1-跳转新页面
          url:'/caseList',
          children:[
            {
              label:'全部案例',
              id:0//0-全部，1-地震预警系统，2-软件开发和接口请求一样
            },
            {
              label:'地震预警系统',
              id:1
            },
            {
              label:'软件开发',
              id:2
            },
          ]
        },
        {
          name:'联系我们',
          type:0,//0-站内锚点跳转 1-跳转新页面
          url:'/contactUs',
          children:[
            {
              label:'联系我们',
              id:1
            },
          ]
        }
      ],
      chooseIndex:-1,
      selectIndex:0,
      selectChild:0,
      showDetail:false,
      drawer: false,
      direction: 'ltr',
      activeSubmenuIndex: null
    };
  },
  computed: {

  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      // 在路由切换前执行的逻辑
      // console.log(to,from,next);
      // console.log('路由即将切换');
      next();
    });
    this.$router.afterEach((to) => {
      // 在路由切换后执行的逻辑
      // console.log(to,from);
      let _currentIndex=this.navList.findIndex(item => item.url ==to.path)
      var _cIndex=0
      if(_currentIndex==-1){
        this.navList.forEach((litem,lindex)=>{
          let cList
          if(litem.children){
            cList=litem.children
            cList.forEach((citem,cindex)=>{
              if(citem.url){
                if(citem.url==to.path){
                  _cIndex=cindex
                  _currentIndex=lindex
                  this.selectChild=_cIndex
                  this.selectIndex=_currentIndex
                }
              }
            })
          }
        })
      }else {
        this.selectIndex=_currentIndex
      }
      // console.log(this.selectIndex,this.selectChild,11);
      // console.log('路由已经切换');
    });

    this.$bus.$on('selected', this.toSelect);

  },
  mounted() {
    this.getMenu()
  },
  methods: {
    getMenu(){
      let that=this
      web_menu().then((res) => {
        if (res.code == 1) {
          that.navList=res.data
        }
      });
    },
    changeNav(item,index,childItem,childIndex){
      if(item.type==0){

        // console.log(this.$route.path,11)
        if(this.$route.path==item.url){
          if(childItem==null){
            return;
          }
          let _obj={
            selectIndex:index,
            parent:item.url,
            selectChild:childIndex+1,
            id:childItem.id
          }
          this.$eventBus.$emit('changeChild', _obj);
          this.$emit('change',JSON.stringify(childItem))
          this.selectIndex=index
          this.selectChild=childIndex

          window.sessionStorage.setItem('selectIndex',index)
          window.sessionStorage.setItem('selectChild',childIndex)
        }else{
          this.$router.push(item.url)
          if(childItem==null){
            return;
          }
          let _obj={
            selectIndex:index,
            parent:item.url,
            selectChild:childIndex+1,
            id:childItem.id
          }
          this.$eventBus.$emit('changeChild', _obj);
          this.$emit('change',JSON.stringify(childItem))
          this.selectIndex=index
          this.selectChild=childIndex
          window.sessionStorage.setItem('selectId',childItem.id)
          window.sessionStorage.setItem('selectIndex',index)
          window.sessionStorage.setItem('selectChild',childIndex)
        }
      }else if(item.type==1){
        if(this.$route.path==childItem.url){
          return
        }else{
          this.$router.push(childItem.url)
          this.selectIndex=index
          this.selectChild=childIndex
          let _obj={
            selectIndex:index,
          }
          this.$eventBus.$emit('changeChild', _obj);
          window.sessionStorage.setItem('selectIndex',index)
          window.sessionStorage.setItem('selectChild',childIndex)
        }
      }
    },
    toSelect(obj){
      // console.log('obj',obj)
      let _obj=JSON.parse(obj)
      if(_obj.selectIndex==-1){
        this.selectChild=_obj.selectChild
      }else{
        this.selectChild=_obj.selectChild
        this.selectIndex=_obj.selectIndex
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.navgation-bar{
  display: flex;
  justify-content: space-between;
  background: rgba(0,0,0,0.85);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
  .logoBox{
    position: absolute;
    padding: 0 1vw;
    left: 0;
    height: 4.2vw;
    display: flex;
    align-items: center;
    border-right: 1vw solid #ff9317;
    color: #e4851a;
    font-size: 1.1vw;
    background: #2c2c2c;
    letter-spacing: 0.1vw;
    span{
      font-family: douyuFont;
    }
    img{
      width: 2vw;
      margin-right: 0.5vw;
    }
  }
  .border{
    position: absolute;
    width: 100%;
    height: 2px;
    background: #636362;
    top: 4.2vw;
    //top: 60px;
  }
  .mobile{
    position: absolute;
    padding: 0 1.8vw;
    right: 0;
    height: 4.2vw;
    //height: 60px;
    display: flex;
    align-items: center;
    color: #ffeddb;
    font-size: 1.2vw;
    background: #ff9317;
    font-family: douyuFont;
    img{
      width: 1vw;
      margin-right: 0.8vw;
    }
  }

}
.navgation{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  .navBox{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1.5vw;
    .mainLabel{
      font-size: 1vw;
      color: #c5c5c5;
      height: 4.2vw;
      line-height: 4.2vw;
      cursor: pointer;
      position: relative;
      padding: 0 1.2vw;
    }
    .activeMain{
      color: #ff9317;
    }
    .activeMain::after{
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #ff9317;
      left: 0;
    }
    .childrenBox{
      display: flex;
      flex-direction: column;
      min-height: 12vw;
      .childTxt{
        color: #c5c5c5;
        margin-top: 0.7vw;
        font-size: 0.7vw;
        cursor: pointer;
      }
      .activeChild{
        color: #ff9317;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.3s ease;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }
    .close{
      position: absolute;
      right: -1vw;
      top: 0;
      height: 4.2vw;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        width: 1vw;
      }
    }
  }
}

@media (max-width: 1001px){
  .navgation-bar{
    height: 50px !important;

    .logoBox{
      height: 50px;
      width: 20vw;
      border-right: 2vw solid #ff9317;
      img{
        width: 20px;
        height: 20px;
      }
      span{
        font-size: 1.5vw !important;
      }
    }

    .navgation{
      display: none;
    }

    button{
      margin: 0;
      padding: 0;
      cursor: pointer;
      border: 0;
      background: none;
    }

    .border{
      top: 50px;
    }

    .mobile{
      height: 50px;
      right: 55px;
      img{
        width: 15px;
        height: 15px;
      }
      span{
        font-size: 10px;
      }
    }

    .nav-open-btn{
      display: block;
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(-50%,-50%);
      z-index: 999;
      height: 30px;
      transition: all 0.4s ease-in-out;

      .line{
        display: block;
        width: 28px;
        height: 5px;
        background-color: orange;
        margin: 4px auto;
        transition: all 0.4s ease-in-out;
        border-radius: 5px;
      }
    }
  }
}

@media (min-width: 1001px){
  .el-drawer{
    display: none;
  }
}

@media (max-width: 768px) {
  .navgation-bar{
    .logoBox{
      width: 39vw;

      span{
        //font-size: 2.5vw !important;
        font-size: 3vw !important;
      }
    }
    .mobile{
      span{
        font-size: 2.4vw;
      }
    }
  }
}

@media (min-width: 481px) and (max-width: 1001px) {
  .nav-open-btn .active{
    right: 20px;
  }
}

//弹出侧边导航栏样式
::v-deep .el-drawer{
  width: 160px !important;
  background: black;
}

.navgation1{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  .navBox1{
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    .mainLabel1{
      font-size: 18px;
      color: white;
      padding: 10px 0;
      display: flex;
      flex-grow: 1;
    }
    .activeMain{
      color: #ff9317;
    }
    .activeMain::after{
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #ff9317;
      left: 0;
    }

    .childrenBox1{
      border-bottom: 1px solid #c5c5c5;

      .childTxt1{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-bottom: 10px;
        color: white;
        opacity: 0.7;
        font-size: 3.2vw;
      }
      .childTxt1:hover{
        color: #ff9317;
      }


      .activeChild{
        color: #ff9317;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.3s ease;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
}
@media (max-width: 360px) {
    .navgation-bar{
      .mobile{
        span{
          font-size: 8px !important;
        }
      }
    }
}
</style>